import React from "react"

import * as s from "./Logo.module.scss"

const Logo = ({ isActive }) => {
  return (
    <svg
      className={s.Logo}
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 359.9 409.7"
      style={{ enableBackground: "new 0 0 359.9 409.7" }}
    >
      <g>
        <g>
          <defs>
            <path
              id="SVGID_1_"
              d="M81.4,403.4V302.8l30.5,0V104.8H90v60.7H6.3V6.3h347.2v159.1H270v-60.7h-22v198.1l30.5,0v100.6H81.4z M180,409.7l104.9,0V296.5h-30.5V111.1h9.3v60.7h96.3V0L0,0v171.8h96.3v-60.7h9.3v185.4H75.1v113.2L180,409.7z"
            />
          </defs>

          <linearGradient
            id="SVGID_00000183956504072145801850000002720507011080442284_"
            gradientUnits="userSpaceOnUse"
            x1="0"
            y1="204.845"
            x2="359.9215"
            y2="204.845"
          >
            <stop offset="0" style={{ stopColor: "#905336" }}></stop>
            <stop offset="0.1868" style={{ stopColor: "#BA9542" }}></stop>
            <stop offset="0.2276" style={{ stopColor: "#C4A353" }}></stop>
            <stop offset="0.3098" style={{ stopColor: "#DEC884" }}></stop>
            <stop offset="0.3956" style={{ stopColor: "#FEF7C2" }}></stop>
            <stop offset="0.4037" style={{ stopColor: "#FAF1B9" }}></stop>
            <stop offset="0.451" style={{ stopColor: "#E2CE8C" }}></stop>
            <stop offset="0.4973" style={{ stopColor: "#D0B56A" }}></stop>
            <stop offset="0.5419" style={{ stopColor: "#C4A353" }}></stop>
            <stop offset="0.5839" style={{ stopColor: "#BD9946" }}></stop>
            <stop offset="0.6209" style={{ stopColor: "#BA9542" }}></stop>
            <stop offset="0.7229" style={{ stopColor: "#B99242" }}></stop>
            <stop offset="0.8047" style={{ stopColor: "#B38941" }}></stop>
            <stop offset="0.8794" style={{ stopColor: "#A97A3E" }}></stop>
            <stop offset="0.9496" style={{ stopColor: "#9C663A" }}></stop>
            <stop offset="1" style={{ stopColor: "#905336" }}></stop>
          </linearGradient>

          <use
            xlinkHref="#SVGID_1_"
            style={{
              overflow: "visible",
              fill: isActive
                ? "url(#SVGID_00000183956504072145801850000002720507011080442284_)"
                : "#ffffff",
            }}
          ></use>

          <clipPath id="SVGID_00000117652977143711148390000005574030548082359484_">
            <use xlinkHref="#SVGID_1_" style={{ overflow: "visible" }}></use>
          </clipPath>
        </g>
      </g>

      <path
        style={{ fill: isActive ? "#FFFFFF" : "transparent" }}
        d="M81.4,403.4V302.8l30.5,0V104.8H90v60.7H6.3V6.3h347.2v159.1H270v-60.7h-22v198.1l30.5,0v100.6 H81.4z M272.2,397v-87.9l-30.5,0V98.4h34.7v60.7h71V12.6H12.7v146.5h71V98.4h34.7v210.7l-30.5,0V397H272.2z"
      />

      <path
        style={{ fill: isActive ? "#CE0E2D" : "#ffffff" }}
        d="M272.2 397L272.2 309.1L241.6 309.2L241.6 98.4L276.3 98.4L276.3 159.1L347.3 159.1L347.3 12.6L12.7 12.6L12.7 159.1L83.6 159.1L83.6 98.4L118.3 98.4L118.3 309.2L87.7 309.1L87.7 397z"
      />

      <path
        style={{ fill: isActive ? "#F5333F" : "transparent" }}
        d="M272.2,397v-87.9l-30.5,0V98.4h34.7v60.7h71V12.6H12.7v146.5h71V98.4h34.7v210.7l-30.5,0V397 H272.2z M96.3,388.5v-70.9l30.5,0V89.9H75.1v60.7H21.2V21.2h317.5v129.4h-53.9V89.9h-51.7v227.8l30.5,0v70.9H96.3z"
      />
      <path
        style={{ fill: isActive ? "#79242E" : "transparent" }}
        d="M75.1 89.9L75.1 150.6L21.2 150.6L12.7 159.1L83.6 159.1L83.6 98.4L118.3 98.4L126.8 89.9z"
      />
      <path
        style={{ fill: isActive ? "#79242E" : "transparent" }}
        d="M276.3 159.1L284.8 150.6L338.7 150.6L338.7 21.2L347.3 12.6L347.3 159.1z"
      />
      <path
        style={{ fill: isActive ? "#79242E" : "transparent" }}
        d="M96.3 388.5L87.7 397L272.2 397L272.2 309.1L263.7 317.7L263.7 388.5z"
      />
      <path
        style={{ fill: isActive ? "#79242E" : "transparent" }}
        d="M284.7 90L233.1 89.9L233.1 317.7L241.6 309.2L241.6 98.4L276.3 98.4z"
      />
      <path
        style={{ fill: isActive ? "#A38255" : "#ffffff" }}
        d="M312.1,407.2c-5.6,0-10.2-4.6-10.2-10.3c0-5.6,4.6-10.2,10.2-10.2c5.7,0,10.3,4.6,10.3,10.2 C322.4,402.7,317.9,407.2,312.1,407.2 M312.1,384.2c-7,0-12.7,5.7-12.7,12.7c0,7,5.7,12.8,12.7,12.8c7.1,0,12.7-5.7,12.7-12.8 C324.9,389.9,319.2,384.2,312.1,384.2 M311.7,395.8h-2.3v-3.7h2.5c1.8,0,2.9,0.6,2.9,1.7C314.8,395.3,314,395.7,311.7,395.8 M316.9,400.7c-1.4-2.4-1.7-2.7-2.9-3.2c2.3-0.3,3.6-1.7,3.6-3.7c0-2.4-1.6-3.9-4.6-3.9h-6.4v13.5h2.7v-5.5h0.8 c1.6,0,2.4,0.7,3.8,3.3l1.2,2.2h3.3L316.9,400.7L316.9,400.7z"
      />
    </svg>
  )
}

export default Logo
