export const parseLink = (link: string): string => {
  if (!link) return ""
  try {
    const url = new URL(link)
    const parsedLink = url.pathname.replace("home-new/", "")
    const trimmedLink = parsedLink.endsWith("/")
      ? parsedLink.substring(0, parsedLink.length - 1)
      : parsedLink
    return trimmedLink
  } catch (_) {
    return link
  }
}
