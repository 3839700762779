import React, { useRef, useState, useEffect } from "react"
import { Props } from "./Tabs.interface"
import Tab from "./Tab"

import * as s from "./Tabs.module.scss"

export const Tabs = ({
  children,
  className,
  controlledTab,
  onClick,
  onTabClick,
}: Props) => {
  const [activeTab, setActiveTab] = useState(null)
  const tabList = useRef([])
  /*eslint-disable*/
  const onClickTabItem = j => {
    onTabClick && onTabClick(j)
    setActiveTab(prev => {
      return j
    })
  }
  /*eslint-enable*/

  useEffect(() => {
    if (controlledTab) {
      tabList.current.childNodes[0].click()
    }
  }, [controlledTab])

  return (
    <div
      onClick={onClick}
      className={`${className} ${activeTab && s.tabs__selectionMade} ${
        activeTab === null && s.tabs__noSelectionMade
      }`}
    >
      <ol ref={tabList} className={s.tabs__list}>
        {children &&
          children.map((child, idx) => {
            const { label } = child.props
            return (
              <React.Fragment key={idx}>
                <Tab
                  activeTab={activeTab}
                  tabId={idx}
                  label={label}
                  onClick={() => onClickTabItem(idx)}
                />
              </React.Fragment>
            )
          })}
      </ol>
      <div id="tab_content">
        {children &&
          children.map(child => {
            if (child.props.id !== activeTab) return undefined
            return child.props.children
          })}
      </div>
    </div>
  )
}
