import React, { ReactNode } from "react"

import * as s from "./Form.module.scss"

import Tick from "../../../assets/icons/tick.inline.svg"

interface CheckBoxInterface {
  label?: ReactNode
  value: boolean
  onChange: any
  error?: any
  className?: string
}

export const CheckBox = ({
  label,
  value,
  onChange,
  error,
  className,
}: CheckBoxInterface) => {
  return (
    <div
      className={`${s.CheckBox} ${error && s.CheckBox___errored} ${
        className && className
      }`}
      onClick={() => onChange(!value)}
      role="checkbox"
      tabIndex={0}
      aria-checked={Boolean(value)}
    >
      <div className={s.CheckBox__inner}>
        <div
          className={`${s.CheckBox__box} ${
            value === true && s.CheckBox__box___active
          }`}
        >
          <Tick />
        </div>
        {label}
      </div>
    </div>
  )
}
