// extracted by mini-css-extract-plugin
export var $animation_name = "Menu-module--$animation_name--ac616";
export var Menu = "Menu-module--Menu--dfd32";
export var MenuCta = "Menu-module--MenuCta--e33df";
export var MenuCta___gradient = "Menu-module--MenuCta___gradient--afcaa";
export var MenuCta__arrow = "Menu-module--MenuCta__arrow--4adc0";
export var MenuCta__subTitle = "Menu-module--MenuCta__subTitle--5b3b8";
export var MenuCta__title = "Menu-module--MenuCta__title--c28f6";
export var MenuFooter___desktop = "Menu-module--MenuFooter___desktop--fa2c1";
export var MenuFooter___mobile = "Menu-module--MenuFooter___mobile--3be61";
export var MenuNav = "Menu-module--MenuNav--f659c";
export var MenuWrapper = "Menu-module--MenuWrapper--403b2";
export var MenuWrapper__lhs = "Menu-module--MenuWrapper__lhs--d67f8";
export var MenuWrapper__menuCtas = "Menu-module--MenuWrapper__menuCtas--d4a88";
export var Menu___isOpen = "Menu-module--Menu___isOpen--bf29c";