import React, { useRef, useEffect } from "react"
import cx from "classnames"
import { useStaticQuery, graphql, Link } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { parseLink } from "../../../utils/urlParser"

import { Nav } from "./Nav"
import { MenuFooter } from "../header/MenuFooter"

import Arrow from "../../../assets/icons/arrow.inline.svg"

import * as s from "./Menu.module.scss"

const Menu = ({ mainMenu, isOpen, currentPage, setShowMenu }) => {
  const { wpPage } = useStaticQuery(graphql`
    query MenuQuery {
      shopCategories: allShopifyCollection {
        nodes {
          handle
          title
        }
      }

      wpPage: wpNodeWithTemplate(template: { templateName: { eq: "Home" } }) {
        ... on WpPage {
          acfHome {
            menuCtas {
              color
              link {
                url
              }
              subTitle
              title
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      quality: 80
                      formats: [WEBP]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const _content = useRef(null)

  useEffect(() => {
    if (isOpen) {
      const doc = document.getElementsByTagName("html")[0]
      doc.style.cssText = "overflow: hidden"
      disableBodyScroll(_content.current)
    } else {
      enableBodyScroll(_content.current)
      const doc = document.getElementsByTagName("html")[0]
      doc.style.cssText = ""
    }
  }, [isOpen])

  return (
    <div
      className={cx(s.Menu, {
        [s.Menu___isOpen]: isOpen,
      })}
      ref={_content}
    >
      <div className={s.MenuWrapper}>
        <div className={s.MenuWrapper__lhs}>
          <div className={s.MenuNav}>
            <Nav
              mainMenuItems={mainMenu}
              currentPage={currentPage}
              setShowMenu={setShowMenu}
            />
          </div>

          <MenuFooter className={s.MenuFooter___desktop} />
        </div>

        <div className={s.MenuWrapper__menuCtas}>
          {wpPage.acfHome.menuCtas.map((cta, i) => {
            return <MenuCta {...cta} key={i} setShowMenu={setShowMenu} />
          })}
        </div>

        <MenuFooter className={s.MenuFooter___mobile} />
      </div>
    </div>
  )
}

const MenuCta = data => {
  return (
    <Link to={parseLink(data.link.url)}>
      <div
        role="image"
        aria-label={data?.image?.altText ? data?.image?.altText : data.title}
        className={cx(s.MenuCta, {
          [s.MenuCta___gradient]: data.image,
        })}
        onClick={() => {
          data.setShowMenu(false)
        }}
        style={
          data.image
            ? {
                backgroundImage: `url(${getSrc(data.image.localFile)})`,
              }
            : data.color
            ? {
                backgroundColor: data.color,
              }
            : {}
        }
      >
        <Arrow className={s.MenuCta__arrow} />

        <span className={s.MenuCta__subTitle}>{data.subTitle}</span>
        <h4 className={s.MenuCta__title}>{data.title}</h4>
      </div>
    </Link>
  )
}

export default Menu
