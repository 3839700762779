exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-preview-tsx": () => import("./../../../src/pages/preview.tsx" /* webpackChunkName: "component---src-pages-preview-tsx" */),
  "component---src-pages-shop-cart-tsx": () => import("./../../../src/pages/shop/cart.tsx" /* webpackChunkName: "component---src-pages-shop-cart-tsx" */),
  "component---src-templates-page-index-js": () => import("./../../../src/templates/page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */),
  "component---src-templates-post-category-index-js": () => import("./../../../src/templates/postCategory/index.js" /* webpackChunkName: "component---src-templates-post-category-index-js" */),
  "component---src-templates-post-index-js": () => import("./../../../src/templates/post/index.js" /* webpackChunkName: "component---src-templates-post-index-js" */),
  "component---src-templates-product-category-index-js": () => import("./../../../src/templates/productCategory/index.js" /* webpackChunkName: "component---src-templates-product-category-index-js" */),
  "component---src-templates-product-index-js": () => import("./../../../src/templates/product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */)
}

