import React from "react"
import { Link } from "../link/Link"

import PageTransitions from "../page-transition/PageTransition"
import * as s from "./Button.module.scss"
import { ButtonProps } from "./Button.interface"

export default function Button({
  to,
  children,
  backgroundColor,
  textColor,
  onClick,
  icon,
  className,
  animated,
}: ButtonProps) {
  const iconToggle = icon && s.button__icon
  return (
    <>
      {animated ? (
        <PageTransitions to={to}>{children}</PageTransitions>
      ) : (
        <>
          {onClick ? (
            <>
              <button
                className={`${s.button} ${className} ${iconToggle}`}
                onClick={onClick}
                style={{
                  backgroundColor: `${icon ? "transparent" : backgroundColor}`,
                  color: textColor,
                }}
              >
                {children ? (
                  <span
                    className={`${s.button__link}`}
                    style={{ backgroundColor: backgroundColor }}
                  >
                    {" "}
                    {children}{" "}
                  </span>
                ) : (
                  <img src={icon} className={s.button__icon} />
                )}
              </button>
            </>
          ) : (
            <>
              <Link
                className={`${s.button} ${className} ${iconToggle}`}
                onClick={onClick}
                style={{
                  backgroundColor: `${icon ? "transparent" : backgroundColor}`,
                  color: textColor,
                }}
                to={to}
              >
                {children ? (
                  <span
                    className={`${s.button__link}`}
                    style={{ backgroundColor: backgroundColor }}
                  >
                    {" "}
                    {children}{" "}
                  </span>
                ) : (
                  <img src={icon} className={s.button__icon} />
                )}
              </Link>
            </>
          )}
        </>
      )}
    </>
  )
}
