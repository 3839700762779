import React from "react"
import { Link as GatsbyLink } from "gatsby"

import { externalCheck } from "../../../utils/externalCheck"
import { Props } from "./Link.interface"

export const Link = ({ children, to, ...props }: Props): JSX.Element => {
  const nullCheck = to === null ? "" : to

  if (externalCheck(to) || to === null) {
    return (
      <a target="__blank" href={nullCheck} rel="noopener noreferrer" {...props}>
        {children}
      </a>
    )
  }

  return (
    <GatsbyLink to={nullCheck} {...props}>
      {children}
    </GatsbyLink>
  )
}
