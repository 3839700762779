import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "redux"

import CartRedcuer from "../ducks/cartSlice"
import SidebarRecucer from "../ducks/sidebarSlice"
import AgeGateReducer from "../ducks/ageGateSlice"
import filterProductsReducer from "../ducks/filterProductsSlice"
import LightboxReducer from "../ducks/lightboxSlice"
import AppSlice from "../ducks/appSlice"

const reducers = combineReducers({
  cart: CartRedcuer.reducer,
  sidebar: SidebarRecucer.reducer,
  filterProducts: filterProductsReducer.reducer,
  agegate: AgeGateReducer.reducer,
  lightbox: LightboxReducer.reducer,
  app: AppSlice.reducer,
})

const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export { store }
