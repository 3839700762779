import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { Link } from "../../common/link/Link"

import * as s from "./Cta.module.scss"
import { setState } from "../../../ducks/sidebarSlice"

export const Cta = ({
  className,
  title,
  link,
  onClick,
  target,
  external,
  disabled,
}: props) => {
  const [sidebarOverride, setSidebarOverride] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (link && link.includes("newsletter")) {
      setSidebarOverride("NEWSLETTER")
    }
  }, [])

  return (
    <div
      className={`${s.Cta} ${className || ""} ${disabled ? s.disabled : ""}`}
      onClick={() => {
        if (onClick) {
          onClick()
        }
        if (sidebarOverride) {
          dispatch(
            setState({
              sidebarIsVisible: true,
              content: sidebarOverride,
            }),
          )
        }
      }}
    >
      <div className={s.Cta__inner}>
        {link && external && (
          <a className={s.Cta__link} href={link} target={target || "_blank"}>
            <span className={s.Cta__link__title}>{title}</span>
          </a>
        )}

        {link && !external && !sidebarOverride && (
          <Link
            className={s.Cta__link}
            to={`${link}`}
            target={target && target}
          >
            <span className={s.Cta__link__title}>{title}</span>
          </Link>
        )}

        {!link && (
          <div className={s.Cta__link}>
            <span className={s.Cta__link__title}>{title}</span>
          </div>
        )}

        {sidebarOverride && (
          <div className={s.Cta__link}>
            <span className={s.Cta__link__title}>{title}</span>
          </div>
        )}
      </div>
    </div>
  )
}
