import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import classNames from "classnames"
import { Link, navigate } from "gatsby"
import { useSelector } from "react-redux"

import CartImg from "../../../assets/svg/shoppingCart.inline.svg"
import SolidCartImg from "../../../assets/icons/shopping-cart-solid.inline.svg"
// import Logo from "../../../assets/svg/logo.inline.svg"
import Logo from "../../common/logo/Logo"

import { Props } from "./Header.interface"
import { Burger } from "./Burger"
import Menu from "../menu/Menu"
import CallOut from "./CallOut"

import * as s from "./Header.module.scss"
import Button from "../../common/button/Button"

import XmasNav from "../../../assets/images/xmas-nav.png"

const isChristmas = false

const Header = ({ pageData, template, showMenu, setShowMenu }: Props) => {
  const { mainMenu, callout } = useStaticQuery(graphql`
    query HeaderQuery {
      mainMenu: allWpMenuItem(filter: { locations: { eq: HEADER_MENU } }) {
        nodes {
          key: id
          parentId
          title: label
          url
        }
      }

      callout: wpNodeWithTemplate(template: { templateName: { eq: "Home" } }) {
        ... on WpPage {
          acfHome {
            calloutTitle
            calloutMessage
          }
        }
      }
    }
  `)

  const [offTop, setOffTop] = useState(false)
  const [isScrollingDown, setIsScrollingDown] = useState(false)

  const isShopPage = template === "Shop"

  const checkout = useSelector(state => state.cart.checkout)
  const hasPassedAgeGate = useSelector(state => state.agegate.havePassed)

  const cartCount =
    checkout && checkout.lineItems.length > 0
      ? checkout.lineItems
          .map(line => line.quantity)
          .reduce((total, line) => {
            return line + total
          })
      : 0

  if (typeof window !== `undefined`) {
    useScrollPosition(({ prevPos, currPos }) => {
      if (currPos.y >= 0) {
        return
      } else if (currPos.y > prevPos.y) {
        if (isScrollingDown) setIsScrollingDown(false)
      } else {
        if (!isScrollingDown && !showMenu) setIsScrollingDown(true)
      }

      if (currPos.y > -20) {
        return
      } else {
        if (!offTop) setOffTop(true)
      }
    })
  }

  const headerClasses = classNames({
    [s.Header]: true,
    [s.Header___ageGateClosed]: hasPassedAgeGate,
    [s.Header___menuActive]: showMenu && hasPassedAgeGate,
    [s.Header___offTop]: (offTop && hasPassedAgeGate) || isShopPage,
    [s.Header___scrollingDown]: isScrollingDown,
  })

  useEffect(() => {
    setOffTop(false)
    setIsScrollingDown(false)
  }, [pageData])

  return (
    <div className={headerClasses}>
      <div
        className={s.Header__inner}
        style={
          isChristmas
            ? {
                backgroundImage: `url(${XmasNav})`,
                boxShadow: `0px 4px 9px rgba(0, 0, 0, 0.25)`,
              }
            : {}
        }
      >
        <div
          className={s.Header__logoWrapper}
          onClick={() => {
            setShowMenu(false)
          }}
        >
          <Link to="/">
            <Logo
              isActive={
                (showMenu && hasPassedAgeGate) ||
                (offTop && hasPassedAgeGate) ||
                isShopPage
              }
            />
          </Link>
        </div>

        <Burger
          onClick={() => {
            setShowMenu(!showMenu)
          }}
          isChristmas={isChristmas}
          active={showMenu}
        />

        <div
          className={`${s.Header__buttonGroup} ${
            isChristmas && s.Header__buttonGroup___christmas
          }`}
        >
          <div
            className={`${s.Header__buttonGroup___cart} ${
              cartCount > 0 && s.Header__buttonGroup___cart___hasItems
            }
            ${
              isChristmas &&
              cartCount > 0 &&
              s.Header__buttonGroup___cart___hasItems_christmas
            }
             `}
            onClick={() => {
              navigate("/shop/cart")
            }}
          >
            {isScrollingDown || offTop || isChristmas ? (
              <SolidCartImg />
            ) : (
              <CartImg />
            )}
            <div>
              <span>{cartCount}</span>
            </div>
          </div>

          <Button
            className={`${s.Header__buttonGroup___shop} ${
              isScrollingDown || offTop || isShopPage || showMenu || isChristmas
                ? ""
                : s.Header__buttonGroup___shopTransparent
            }`}
            to="/shop"
          >
            Shop
          </Button>
        </div>
      </div>

      <CallOut className={s.Header__Calllout} data={callout.acfHome} />

      <Menu
        isOpen={showMenu}
        mainMenu={mainMenu}
        currentPage={pageData}
        setShowMenu={setShowMenu}
      />
    </div>
  )
}

export default Header
