// extracted by mini-css-extract-plugin
export var $animation_name = "Form-module--$animation_name--a9f44";
export var CheckBox = "Form-module--CheckBox--9460e";
export var CheckBox___errored = "Form-module--CheckBox___errored--1885d";
export var CheckBox__box = "Form-module--CheckBox__box--f9dd0";
export var CheckBox__box___active = "Form-module--CheckBox__box___active--8c2a4";
export var CheckBox__inner = "Form-module--CheckBox__inner--70f68";
export var DropDownInner = "Form-module--DropDownInner--95ff2";
export var DropDownInner__arrow = "Form-module--DropDownInner__arrow--3a6ad";
export var DropDownInner__arrow___focused = "Form-module--DropDownInner__arrow___focused--dd165";
export var DropDownInner__trigger = "Form-module--DropDownInner__trigger--43765";
export var DropDownInner__value = "Form-module--DropDownInner__value--871be";
export var DropDownMobile = "Form-module--DropDownMobile--efbd2";
export var DropDownOptions = "Form-module--DropDownOptions--7b311";
export var DropDownOptions__option = "Form-module--DropDownOptions__option--0bbce";
export var InputInner = "Form-module--InputInner--797d2";
export var InputWrapper = "Form-module--InputWrapper--e0d9e";
export var InputWrapper___errored = "Form-module--InputWrapper___errored--19efb";
export var InputWrapper___filled = "Form-module--InputWrapper___filled--84b52";
export var InputWrapper___focused = "Form-module--InputWrapper___focused--77b34";
export var Placeholder = "Form-module--Placeholder--f44ca";