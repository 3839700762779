import { createSlice } from "@reduxjs/toolkit"

const app = createSlice({
  name: "filterProducts",
  initialState: {
    products: [],
    sort: "Newest",
    filter: "All",
  },
  reducers: {
    setState: (state, action) => {
      return { ...state, ...action.payload }
    },
  },
})

export const { setState } = app.actions

export default app
