import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import PageTransitionSlide from "../../../assets/images/transitionSlide.png"

import "./PageTransition.module.scss"
import { Props } from "./PageTransition.interface"

const PageTransition = ({ children, to, className }: Props) => {
  return (
    <AniLink
      cover
      className={`${className}`}
      direction="up"
      duration={1}
      to={to}
      bg={`
      url(${PageTransitionSlide}) center/cover no-repeat
      fixed
      padding-box
      content-box
      white
    `}
    >
      {children}
    </AniLink>
  )
}

export default PageTransition
