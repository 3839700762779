// extracted by mini-css-extract-plugin
export var $animation_name = "Header-module--$animation_name--626c4";
export var Burger = "Header-module--Burger--dd5f5";
export var Burger___active = "Header-module--Burger___active--a4210";
export var Burger___christmas = "Header-module--Burger___christmas--481dd";
export var Burger__inner = "Header-module--Burger__inner--b18b1";
export var Calllout = "Header-module--Calllout--6742a";
export var Calllout___hidden = "Header-module--Calllout___hidden--bba26";
export var Calllout__close = "Header-module--Calllout__close--46417";
export var Calllout__inner = "Header-module--Calllout__inner--9f5b1";
export var Calllout__message = "Header-module--Calllout__message--dd9a0";
export var Calllout__title = "Header-module--Calllout__title--42f0a";
export var Header = "Header-module--Header--1b08f";
export var Header__Calllout = "Header-module--Header__Calllout--021ea";
export var Header___ageGateClosed = "Header-module--Header___ageGateClosed--3395d";
export var Header___menuActive = "Header-module--Header___menuActive--b4b50";
export var Header___offTop = "Header-module--Header___offTop--385f4";
export var Header___scrollingDown = "Header-module--Header___scrollingDown--ab00b";
export var Header__buttonGroup = "Header-module--Header__buttonGroup--5ad10";
export var Header__buttonGroup___cart = "Header-module--Header__buttonGroup___cart--38929";
export var Header__buttonGroup___cart___hasItems = "Header-module--Header__buttonGroup___cart___hasItems--3a880";
export var Header__buttonGroup___cart___hasItems_christmas = "Header-module--Header__buttonGroup___cart___hasItems_christmas--52714";
export var Header__buttonGroup___christmas = "Header-module--Header__buttonGroup___christmas--b8629";
export var Header__buttonGroup___shop = "Header-module--Header__buttonGroup___shop--84455";
export var Header__buttonGroup___shopTransparent = "Header-module--Header__buttonGroup___shopTransparent--6be5f";
export var Header__inner = "Header-module--Header__inner--021e7";
export var Header__logoWrapper = "Header-module--Header__logoWrapper--573f9";