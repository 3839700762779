import { createSlice } from "@reduxjs/toolkit"

const app = createSlice({
  name: "sidebar",
  initialState: {
    sidebarIsVisible: false,
    upsellIsVisible: false,
    checkoutTransition: false,
    content: "CART",
  },

  reducers: {
    setState: (state, action) => {
      return { ...state, ...action.payload }
    },
  },
})

export const { setState } = app.actions

export default app
