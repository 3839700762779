import React from "react"
import { helmetJsonLdProp } from "react-schemaorg"
import { Helmet } from "react-helmet"

export const Schema = ({ data }) => {
  if (!data) return <></>
  return (
    <Helmet
      script={[helmetJsonLdProp({ "@context": "https://schema.org", ...data })]}
    />
  )
}
