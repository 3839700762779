import React, { useState, useEffect } from "react"
import { enableBodyScroll } from "body-scroll-lock"
import { Provider } from "react-redux"
import { store } from "../state/createStore"
import { useSelector } from "react-redux"

import Logo from "../assets/images/tennentsLogo.png"

import Header from "@components/main/header/Header"
import { Schema } from "@components/common/schema/Schema"
import AgeGate from "@components/main/age-gate/AgeGate"
import { OldBrowserWarning } from "@components/main/old-browser-warning/OldBrowserWarning"
import { Lightbox } from "@components/common/video/Lightbox"
import { SideBar } from "@components/main/sidebar/SideBar"

import "../styles/baseline.scss"
import "swiper/css/bundle"
import * as s from "./Index.module.scss"

import { Helmet } from "react-helmet"

const Index = ({ children, pageContext, data, location }) => {
  const [showMenu, setShowMenu] = useState(false)
  const { wpPage } = data || {}

  useEffect(() => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
  }, [])

  useEffect(() => {
    if (location.pathname.includes("terms")) {
      let doc = document.getElementsByTagName("html")[0]
      enableBodyScroll(document.body)
      doc.style.overflow = "scroll"
    }
  }, [])

  let template = wpPage && wpPage.template ? wpPage.template.templateName : null

  if (location.pathname.includes("shop")) {
    template = "Shop"
  }

  if (location.pathname.includes("terms")) {
    template = "Support"
  }

  if (location.pathname.includes("news")) {
    template = "Blog"
  }

  return (
    <Provider store={store}>
      <div>
        <Helmet>
          <meta
            name="facebook-domain-verification"
            content="essc4qxtxbbc2ndvn1myuwnwo32jnf"
          />
        </Helmet>

        <Lightbox />
        <OldBrowserWarning />

        <Schema
          data={{
            "@type": "Organization",
            name: "Tennent's",
            url: "https://www.tennents.co.uk",
            logo: `${process.env.URL}/${Logo}`,
            sameAs: [
              "https://www.facebook.com/TennentsLager",
              "https://twitter.com/TennentsLager",
              "https://www.instagram.com/TennentsLager",
              "https://www.youtube.com/channel/UCkVCQmI0EMGUWNBEYRsrmvA",
            ],
          }}
        />
        {/**
         * check template name
         */}
        {template !== "Support" && <AgeGate />}

        {template !== "Multi App" && (
          <>
            <SideBar pageContext={pageContext} />
            <Header
              pageData={pageContext}
              template={template}
              showMenu={showMenu}
              setShowMenu={val => setShowMenu(val)}
              location={location}
            />
          </>
        )}

        <ContentWrapper>{children}</ContentWrapper>
      </div>
    </Provider>
  )
}

export default Index

const ContentWrapper = ({ children }) => {
  const hasPassedAgeGate = useSelector(state => state.agegate.havePassed)

  return (
    <div
      className={`${s.ContentWrapper} ${
        !hasPassedAgeGate && s.ContentWrapper___ageGate
      }`}
    >
      {children}
    </div>
  )
}
