import React, { useState } from "react"
import axios from "axios"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import * as l from "../SideBar.module.scss"
import * as s from "./Newsletter.module.scss"

import { TextInput } from "../../../common/form/TextInput"
import { CheckBox } from "../../../common/form/CheckBox"
import { Cta } from "../../../common/cta/Cta"
import { Link } from "gatsby"

const Newsletter = () => {
  const [emailAddress, setEmailAddress] = useState("")
  const [name, setName] = useState("")
  const [error, setError] = useState("")
  const [marketing, setMarketing] = useState(false)
  const [sent, setSent] = useState(false)
  const [sending, setSending] = useState(false)
  function validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const submit = async () => {
    if (!validateEmail(emailAddress)) {
      setError("Please enter a valid email address")
      return
    }
    if (!name) {
      setError("Please enter your name")
      return
    }
    if (!marketing) {
      setError("Please check the box")
      return
    }
    setError(null)
    setSending(true)
    /* eslint-disable */
    const contactRes = await axios
      .request({
        method: "POST",
        url: "/.netlify/functions/klaviyo-newsletter",
        data: {
          name: name,
          email: emailAddress,
        },
      })
      .then(function (response) {
        console.log(response.data)
        setSent(true)
        setSending(false)
      })
      .then(() => {
        trackCustomEvent({
          category: "Newsletter",
          action: "Signup",
        })
      })
      .catch(function (error) {
        console.log(error)
        setError("Unexpected error. Please try again later.")
        setSending(false)
      })
    /* eslint-enable */
  }

  return (
    <>
      <div className={l.Content__header}>
        <h3>Newsletter</h3>
      </div>
      {!sent && (
        <div className={l.Content__wysiwygContent}>
          <p>Sign up to the Tennent’s newsletter.</p>
          <TextInput
            placeholder={"Your Name"}
            value={name}
            onChange={e => {
              setName(e.target.value)
            }}
            className={s.Input}
          />
          <TextInput
            placeholder={"Your Email Address"}
            value={emailAddress}
            onChange={e => {
              setEmailAddress(e.target.value)
            }}
            className={s.Input}
          />
          <CheckBox
            value={marketing}
            onChange={() => {
              setMarketing(!marketing)
            }}
            className={`${s.Input} ${s.Input___checkbox}`}
            label={
              <p>
                Check this box if you want to receive marketing communications
                from Tennent’s Lager.{" "}
                <Link to="/privacy-policy">Privacy Policy</Link>
              </p>
            }
          />
          {error && <span className={s.Error}>{error}</span>}
          <Cta title={"Sign up"} disabled={sending} onClick={submit} />
        </div>
      )}

      {sent && (
        <div className={l.Content__wysiwygContent}>
          <p>You're signed up!</p>
        </div>
      )}
    </>
  )
}

export default Newsletter
