import React, { useState, useEffect } from "react"

import * as s from "./Header.module.scss"

import Cross from "../../../assets/icons/cross.svg"

const CallOut = ({ className, data }) => {
  const date = new Date()
  date.setTime(date.getTime() + 3600000 * 24 * 14)

  const [isHidden, setIsHidden] = useState(true)

  useEffect(() => {
    if (!data.calloutTitle && !data.calloutMessage) {
      window.localStorage.removeItem("callOut")
    }
  }, [data])

  useEffect(() => {
    if (typeof window === "object") {
      if (window.localStorage.getItem("callOut")) {
        setIsHidden(true)
      } else {
        setIsHidden(false)
      }
    }
  }, [])

  if (!data.calloutTitle && !data.calloutMessage) {
    return <></>
  }

  return (
    <div
      className={`${s.Calllout} ${isHidden == true && s.Calllout___hidden} ${
        className && className
      }`}
    >
      <div className={s.Calllout__inner}>
        <span className={s.Calllout__title}>{data.calloutTitle}</span>
        <span className={s.Calllout__message}>{data.calloutMessage}</span>
      </div>
      <div
        className={s.Calllout__close}
        onClick={() => {
          if (typeof window === "object") {
            window.localStorage.setItem("callOut", true)
            setIsHidden(true)
          }
        }}
      >
        <img src={Cross} alt="Close" />
      </div>
    </div>
  )
}

export default CallOut
