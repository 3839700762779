import React from "react"

import * as s from "./Header.module.scss"

export const Burger = ({ active, onClick, isChristmas }) => {
  return (
    <div
      className={`${s.Burger} ${isChristmas && s.Burger___christmas}`}
      onClick={onClick}
    >
      <div className={`${s.Burger__inner} ${active && s.Burger___active} `}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}
