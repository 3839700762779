import React from "react"

import * as s from "./AddToCartButton.module.scss"

export const AddToCartButton = ({ title, className, onClick, added }) => {
  return (
    <div
      role="button"
      className={`${s.AddToCartButton} ${className ? className : ""} ${
        added ? s.AddToCartButton___added : ""
      }`}
      onClick={onClick}
    >
      <span>{added ? "Added to cart" : title}</span>
    </div>
  )
}
