import { createSlice } from "@reduxjs/toolkit"

const app = createSlice({
  name: "app",
  initialState: {
    pageLocked: false,
  },
  reducers: {
    setAppState: (state, action) => {
      return { ...state, ...action.payload }
    },
  },
})

export const { setAppState } = app.actions

export default app
