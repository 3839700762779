import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { detect } from "detect-browser"

import { LogoHeader } from "../../main/logo-header/LogoHeader"
import { Link } from "../../common/link/Link"

import * as s from "./OldBrowserWarning.module.scss"

export const OldBrowserWarning = () => {
  const [isOldBrowser, setIsOldBrowser] = useState(false)
  const oldBrowser = detect()

  useEffect(() => {
    if (isSupported()) {
      setIsOldBrowser(true)
    }
  }, [])

  const isSupported = () => {
    if (!oldBrowser) {
      return true
    }
    switch (oldBrowser.name) {
      case "chrome":
      case "firefox":
      case "edge":
        return false
        break
      case "ie":
        return true
        break
      default:
        return true
    }
  }

  const { wpNodeWithTemplate } = useStaticQuery(graphql`
    query OldWarningQuery {
      wpNodeWithTemplate(template: { templateName: { eq: "Home" } }) {
        ... on WpPage {
          acfHome {
            warningBackgroundImage {
              sourceUrl
            }
            warningBlurb
            warningTitle
            recommendedDownloads {
              downloadLink
              browserName
              browserLogo {
                sourceUrl
              }
            }
          }
        }
      }
    }
  `)

  if (isOldBrowser) {
    const {
      warningBackgroundImage,
      warningBlurb,
      warningTitle,
      recommendedDownloads,
    } = wpNodeWithTemplate.acfHome

    return (
      <div
        className={s.oldBrowserWarning}
        style={{
          backgroundImage: `url(${warningBackgroundImage.sourceUrl})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <LogoHeader />

        <div className={s.oldBrowserWarning__inner}>
          <div className={s.oldBrowserWarning__inner___headline}>
            <h2>{warningTitle}</h2>

            <div className={s.oldBrowserWarning__inner___headlineBlurb}>
              <p>{warningBlurb}</p>
            </div>
          </div>

          <div className={s.oldBrowserWarning__inner___buttonContainer}>
            {recommendedDownloads.map((download, idx) => {
              return (
                <div
                  className={s.oldBrowserWarning__inner___buttonContainerButton}
                  key={idx}
                >
                  <Link to={download.downloadLink}>
                    <img src={download.browserLogo.sourceUrl} />
                  </Link>

                  <Link to={download.browserLogo.sourceUrl}>
                    <p>
                      <span>{download.browserName}</span>
                    </p>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  } else {
    return <></>
  }
}
