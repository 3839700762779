import React from "react"

type iTabProps = {
  activeTab: string
  label: string
  onClick: () => void
  ref?: Record<string, unknown>
  tabId?: string
}

import * as s from "./Tab.module.scss"

const Tab = ({ activeTab, label, onClick, tabId }: iTabProps) => {
  const activeTabClass =
    activeTab === tabId
      ? `${s.Tab__listItem___active}`
      : `${s.Tab__listItem___inActive}`

  return (
    <li
      onClick={onClick}
      key={tabId}
      className={`${s.Tab__listItem} ${activeTabClass}`}
    >
      <span>{label}</span>
    </li>
  )
}

export default Tab
