// extracted by mini-css-extract-plugin
export var $animation_name = "Video-module--$animation_name--22ef5";
export var Card = "Video-module--Card--1ef25";
export var Card__Player_Wrapper = "Video-module--Card__Player_Wrapper--56cbc";
export var Card__cover = "Video-module--Card__cover--616e5";
export var Card__overlay = "Video-module--Card__overlay--8a658";
export var Card__playBtn = "Video-module--Card__playBtn--184be";
export var CloseBtn = "Video-module--CloseBtn--16648";
export var Lightbox = "Video-module--Lightbox--a4600";
export var Lightbox___active = "Video-module--Lightbox___active--802a5";
export var Lightbox__inner = "Video-module--Lightbox__inner--5e82b";
export var Wrapper = "Video-module--Wrapper--c6f94";
export var Wrapper__cover = "Video-module--Wrapper__cover--9e4f1";