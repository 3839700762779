import React, { useState } from "react"

import * as s from "./Form.module.scss"

interface TextInputInterface {
  placeholder?: string
  solidPlaceholder?: string
  value: any
  onChange: any
  onKeyDown?: any
  error?: any
  className?: any
  onBlur?: () => void
  requireEmail?: any
}

export const TextInput = ({
  placeholder,
  solidPlaceholder,
  value,
  onChange,
  onBlur,
  onKeyDown,
  error,
  className,
  requireEmail,
}: TextInputInterface) => {
  const [focused, setFocused] = useState(false)
  return (
    <div
      className={`${s.InputWrapper} ${className && className} ${
        focused && s.InputWrapper___focused
      } ${value && !focused && s.InputWrapper___filled} ${
        error && s.InputWrapper___errored
      }`}
    >
      <span className={s.Placeholder}>{placeholder}</span>
      <div>
        <input
          type={requireEmail ? "email" : "text"}
          onFocus={() => setFocused(true)}
          placeholder={solidPlaceholder && solidPlaceholder}
          onBlur={e => {
            setFocused(false)
            if (onBlur) {
              onBlur(e)
            }
          }}
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={value}
          required={requireEmail}
        />
      </div>
    </div>
  )
}
