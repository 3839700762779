import React, { useEffect, useState, useRef } from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import * as s from "./Upsell.module.scss"

import toBase64String from "@utils/toBase64String"

import useOnClickOutside from "../../../../hooks/click-outside"
import { Cta } from "../../../common/cta/Cta"
import { AddToCartButton } from "../../add-to-cart-button/AddToCartButton"
import { addToCart } from "../../../../ducks/cartSlice"
import { setAppState } from "../../../../ducks/appSlice"
import { getSetRecommendedProducts } from "../../../../utils/getSetRecommendedProducts"
import { setState } from "../../../../ducks/sidebarSlice"

const UpSell = ({ data }) => {
  const dispatch = useDispatch()
  const checkout = useSelector(state => state.cart.checkout)
  const _innerModal = useRef(null)
  const cart = useSelector(state => state.cart)

  const [recommendedProducts, setRecommendedProducts] = useState([])
  const [addedItems, setAddedItems] = useState([])
  const [continueDisabled, setContinueDisabled] = useState(false)

  useOnClickOutside(_innerModal, () => {
    dispatch(
      setState({
        sidebarIsVisible: true,
        upsellIsVisible: false,
      }),
    )
    dispatch(setAppState({ pageLocked: false }))
  })

  useEffect(() => {
    if (!checkout || !checkout.lineItems[0]) return

    const currentProduct = checkout.lineItems[0].variant.product.id

    if (recommendedProducts.length < 1) {
      fetchData(currentProduct)
      dispatch(setAppState({ pageLocked: true }))
    }
  }, [checkout])

  if (!cart.checkout) {
    return <span></span>
  }

  const fetchData = async productId => {
    try {
      const shopifyRes = await axios.post(`/.netlify/functions/recommended`, {
        productId,
      })
      const recomendations = shopifyRes.data.products.filter(item => {
        return item.type !== "Personalised" && item.variants.length < 2
      })

      if (recomendations.length < 1) {
        openCheckout()
      } else {
        setRecommendedProducts(
          getSetRecommendedProducts(data, recomendations.slice(0, 3), checkout),
        )
      }
    } catch (e) {
      openCheckout()
    }

    dispatch(
      setState({
        sidebarIsVisible: false,
      }),
    )
  }

  const handleAddToCart = product => {
    const checkoutId = cart.checkout.id

    setAddedItems([...addedItems, product.id])

    if (product.tag === "staticQuery") {
      dispatch(
        addToCart({
          product: {
            ...product.variants[0],
            title: product.title,
            quantity: 1,
          },
          checkoutId,
        }),
      )
    } else {
      dispatch(
        addToCart({
          product: {
            shopifyId: toBase64String(
              `gid://shopify/ProductVariant/${product.variants[0].id}`,
            ),
            quantity: 1,
          },
          checkoutId,
        }),
      )
    }

    window.gtag("event", "add_to_cart", {
      currency: "GBP",
      value: product.price,
      items: [
        {
          item_id: product.id,
          item_name: product.title,
          item_category: product.type,
          item_list_name: "Upsell",
        },
      ],
    })
  }

  const openCheckout = () => {
    setContinueDisabled(true)
    dispatch(setAppState({ pageLocked: false }))
    let gaLinkerParam = null
    window.ga(function (tracker) {
      gaLinkerParam = tracker.get("linkerParam")
    })
    const queryStringSuffix = gaLinkerParam ? `&${gaLinkerParam}` : ""
    window.location.href = `${checkout.webUrl}${queryStringSuffix}`
  }

  return (
    <div className={s.UpSell}>
      <div
        ref={_innerModal}
        className={`${s.UpSellContent} ${
          recommendedProducts.length > 0 ? s.UpSellContent___in : ""
        }`}
      >
        <div className={s.UpSellContent__header}>
          <h3>While you’re here?</h3>
        </div>
        <div className={s.UpSellContent__products}>
          <div className={s.ProductList}>
            {recommendedProducts.map((product, i) => {
              return (
                <div className={s.ProductList__product} key={i}>
                  <Link
                    to={`/shop/${product.handle}`}
                    onClick={dispatch(setAppState({ pageLocked: false }))}
                    className={s.ProductList__image}
                    style={{
                      backgroundImage: `url(${
                        product.variants[0].featured_image
                          ? product.variants[0].featured_image.src
                          : product.images
                          ? product.images[0]
                          : product.variants[0].image.originalSrc
                      })`,
                    }}
                  />
                  <Link
                    to={`/shop/${product.handle}`}
                    onClick={dispatch(setAppState({ pageLocked: false }))}
                    className={s.ProductList__title}
                  >
                    <span>
                      {product.variants.length > 1
                        ? product.variants[0].name
                        : product.title}
                    </span>
                  </Link>
                  <div className={s.ProductList__price}>
                    <span>
                      £
                      {product.tag !== "staticQuery"
                        ? (product.variants[0].price / 100).toFixed(2)
                        : product.variants[0].price}
                    </span>
                  </div>

                  <AddToCartButton
                    added={addedItems.includes(product.id)}
                    title={"Add to cart"}
                    className={s.ProductList__cta}
                    onClick={() => {
                      trackCustomEvent({
                        category: "Cart",
                        action: "upsell product click",
                      })
                      handleAddToCart(product)
                    }}
                  />
                </div>
              )
            })}
          </div>
        </div>
        <div className={s.UpSellContent__footer}>
          <Cta
            title={"Continue to checkout"}
            onClick={openCheckout}
            className={s.UpSellContent__Cta}
            disabled={continueDisabled}
          />
        </div>
      </div>
    </div>
  )
}

export default function UpsellProps(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          products: allShopifyCollection {
            nodes {
              products {
                id
                shopifyId
                handle
                title
                tags
                metafields {
                  key
                  value
                }
                variants {
                  id
                  shopifyId
                  price
                  title
                  image {
                    originalSrc
                  }
                }
              }
            }
          }
        }
      `}
      render={data => <UpSell data={data} {...props} />}
    />
  )
}
