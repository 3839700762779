import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import * as s from "./MenuFooter.module.scss"
import DrinkAware from "../../../assets/images/drink-aware@2x.png"

export const MenuFooter = ({ className }) => {
  return (
    <div className={`${s.MenuFooter} ${className && className}`}>
      <div className={s.MenuFooter__logos}>
        <div>
          <StaticImage
            loading="eager"
            placeholder="none"
            className={s.MenuFooter__logos___madeForThis}
            width={60}
            height={45}
            src="../../../assets/images/madeForThisColor.png"
            alt="Made For this"
            layout="fixed"
          />
        </div>
        <div>
          <img src={DrinkAware} alt="Drink Aware" width={171} height={38} />
        </div>
      </div>
      <div className={s.MenuFooter__blurb}>
        <p>
          Please do not share with anyone under the legal purchase age for
          alcohol. Drink Responsibly.
        </p>
      </div>
    </div>
  )
}
