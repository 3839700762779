import React from "react"

import * as s from "./QuantitySelector.module.scss"

export const QuantitySelector = ({ quantity, updateQuantity }) => {
  return (
    <div className={s.QuantitySelector}>
      <div
        className={s.QuantitySelector__button}
        onClick={() => updateQuantity(-1)}
        role="button"
        tabIndex="0"
      >
        <span>-</span>
      </div>
      <div className={s.QuantitySelector__value}>
        <span>{quantity}</span>
      </div>
      <div
        className={s.QuantitySelector__button}
        onClick={() => updateQuantity(1)}
        role="button"
        tabIndex="0"
      >
        <span>+</span>
      </div>
    </div>
  )
}
