import { createSlice } from "@reduxjs/toolkit"

const app = createSlice({
  name: "agegate",
  initialState: {
    havePassed: true,
  },
  reducers: {
    setState: (state, action) => {
      return { ...state, ...action.payload }
    },
  },
})

export const checkAgeGate = () => async dispatch => {
  if (typeof window === "object") {
    if (window.localStorage.getItem("agegate")) {
      dispatch(
        setState({
          havePassed: true,
        })
      )
    } else {
      dispatch(
        setState({
          havePassed: false,
        })
      )
    }
  }
}

export const acceptAgeGate = () => async dispatch => {
  if (typeof window === "object") {
    window.localStorage.setItem("agegate", true)
    dispatch(
      setState({
        havePassed: true,
      })
    )
  }
}

export const { setState } = app.actions

export default app
